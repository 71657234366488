<template>
    <div class="banner">
        <div class="banner__inner">
            <div class="banner__wrapper container">
                <div class="banner__content content">
                    <h1 class="content__heading">БЕЛОРУССКИЙ АЛКОГОЛЬ</h1>
                    <h3 class="content__subheading">ООО “РСГ”</h3>
                    <p class="content__text">Имортер Белорусской алкогольной продукции продукции в республике Крым</p>
                </div>

                <swiper 
                    :slides-per-view="1"
                    :space-between="20"
                    :modules="modules"
                    :pagination="true"
                    :style="{
                            '--swiper-pagination-color': '#79180E',
                            '--swiper-pagination-bullet-inactive-color': '#A15E57',
                        }"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    class="banner__slider slider">

                    <swiper-slide class="slider__slide">
                        <img 
                            class="slider__img slider__img_2"
                            src="../images/main-slider/2.png">
                    </swiper-slide>

                    <swiper-slide class="slider__slide">
                        <img 
                            class="slider__img slider__img_2"
                            src="../images/main-slider/1.png">
                    </swiper-slide>
                    
                    <swiper-slide class="slider__slide">
                        <img 
                            class="slider__img"
                            src="../images/main-slider/3.png">
                    </swiper-slide>
                </swiper>
            </div>
            <img src="../images/main-slider/flag.svg" class="banner__flag">
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Scrollbar, A11y } from 'swiper/modules';
import { ref } from 'vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    setup() {
        const onSwiper = (swiper) => {

        };
        const onSlideChange = () => {

        };
        return {
            onSwiper,
            onSlideChange,
            modules: [ Pagination, Scrollbar, A11y]
        };
    },
    components: {
        Swiper,
        SwiperSlide
    },
}
</script>

<style scoped>
    .banner__inner{
        height: 100vh;
        background: url(../images/main-slider/bg.png) no-repeat;
        background-size: 100% 100%;
    }
    .banner__wrapper{
        position: relative;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
    }
    .content__heading{
        font-size: 84px;
        max-width: 705px;
        line-height: 108%;
        font-weight: 900;
        margin-bottom: 160px;
    }
    .content__subheading{
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 15px;
    }
    .content__text{
        max-width: 530px;
        font-size: 20px;
    }
    .banner__slider{
        position: relative;
        cursor: grab;
        right: 0;
        bottom: -125px;
    }
    .slider__slide{
        display: flex;
        justify-content: center;
    }
    .banner__flag{
        position: absolute;
        bottom: 0;
        right: 0;
    }
    @media (max-width: 1240px) {
        .content__heading{
            font-size: 54px;
        }
    }
    @media (max-width: 992px){
        .banner__wrapper{
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        .banner__content{
            margin-top: 120px;
        }
        .content__heading{
            margin-bottom: 30px;
        }
        .banner__slider{
            bottom: auto;
            max-width: 400px;
            max-height: 500px;
        }
        .banner__flag{
            display: none;
        }
        .slider__slide{
            justify-content: center;
            /* max-height: 500px; */
        }
        .slider__img{
            width: 100%;
            object-fit: contain;
        }
    }
    @media (max-width: 600px){
        .banner__inner{
            height: 100vh;
            background: url(../images/main-slider/bg-mob.png) no-repeat;
            background-size: 100% 100%;
            height: 80vh;
        }
        .banner__content{
            padding-left: 40px;
            padding-right: 40px;
            margin-top: 100px;
        }
        .banner__slider{
            max-width: 100%;
        }
        .content__heading{
            font-size: 36px;
            max-width: 300px;
            margin-bottom: 20px;
        }
        .content__subheading{
            font-size: 16px;
            margin-bottom: 10px;
        }
        .content__text{
            font-size: 14px;
        }
        .slider__slide{
            max-height: 390px;
            padding-bottom: 25px;
        }
        .slider__img_2{
            margin-right: -10px;
        }
    }
</style>